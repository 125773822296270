import Countdown from 'react-countdown'
import { StatBar } from '../stats/StatBar'
import { Histogram } from '../stats/Histogram'
import { GameStats } from '../../lib/localStorage'
import { shareStatus } from '../../lib/share'
// import { tomorrow } from '../../lib/words'
import { BaseModal } from './BaseModal'
import {
  STATISTICS_TITLE,
  GUESS_DISTRIBUTION_TEXT,
  NEW_WORD_TEXT,
  SHARE_TEXT,
} from '../../constants/strings'

type Props = {
  isOpen: boolean
  handleClose: () => void
  guesses: string[]
  gameStats: GameStats
  isGameLost: boolean
  isGameWon: boolean
  handleShare: () => void
  isHardMode: boolean
  isDarkMode: boolean
  isHighContrastMode: boolean
}

export const StatsModal = ({
  isOpen,
  handleClose,
  guesses,
  gameStats,
  isGameLost,
  isGameWon,
  handleShare,
  isHardMode,
  isDarkMode,
  isHighContrastMode,
}: Props) => {
  // if (gameStats.totalGames <= 0) {
  //   return (
  //     <BaseModal
  //       title={STATISTICS_TITLE}
  //       isOpen={isOpen}
  //       handleClose={handleClose}
  //       isGameWon={isGameWon}
  //       isGameLost={isGameLost}
  //     >
  //       {/* <StatBar gameStats={gameStats} /> */}
  //     </BaseModal>
  //   )
  // }
  return (
    <BaseModal
      title={STATISTICS_TITLE}
      isOpen={isOpen}
      handleClose={handleClose}
      isGameWon={isGameWon}
      isGameLost={isGameLost}
    >
      {(isGameLost || isGameWon) && (
        <div
          // className="mt-5 sm:mt-6 columns-2 dark:text-white"
          className="flex items-center justify-center py-10 px-4 text-center sm:block sm:p-0"
        >
          <button
            type="button"
            className="mt-2 w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
            onClick={() => {
              if (window.flutter_inappwebview) {
                if (isGameLost) {
                  window.flutter_inappwebview.callHandler(
                    'onMatchEnded',
                    'wordle_lost'
                  )
                } else {
                  window.flutter_inappwebview.callHandler(
                    'onMatchEnded',
                    'wordle_win'
                  )
                }
              }
            }}
          >
            Back
          </button>
        </div>
      )}
    </BaseModal>
  )
}
