import { MAX_WORD_LENGTH } from '../../constants/settings'
import { Cell } from './Cell'

type Props = {
  maxWord: number
}

export const EmptyRow = ({ maxWord }: Props) => {
  const emptyCells = Array.from(Array(maxWord))

  return (
    <div className="flex justify-center mb-1">
      {emptyCells.map((_, i) => (
        <Cell key={i} />
      ))}
    </div>
  )
}
