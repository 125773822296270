import { useState, useEffect } from 'react'
import { Grid } from './components/grid/Grid'
import { Keyboard } from './components/keyboard/Keyboard'
import { StatsModal } from './components/modals/StatsModal'
import {
  GAME_TITLE,
  GAME_COPIED_MESSAGE,
  NOT_ENOUGH_LETTERS_MESSAGE,
} from './constants/strings'
import { REVEAL_TIME_MS } from './constants/settings'
import { unicodeLength } from './lib/words'
import { loadStats } from './lib/stats'
import { getStoredIsHighContrastMode } from './lib/localStorage'
import { default as GraphemeSplitter } from 'grapheme-splitter'
import ReactLoading from 'react-loading'
import './App.css'
import { AlertContainer } from './components/alerts/AlertContainer'
import { useAlert } from './context/AlertContext'
import * as Colyseus from 'colyseus.js'
import titleImg from './assets/title.svg'

declare global {
  interface Window {
    flutter_inappwebview: any
  }
}

function App() {
  const prefersDarkMode = window.matchMedia(
    '(prefers-color-scheme: dark)'
  ).matches

  let Client: Colyseus.Client
  let sessionId: string
  let userId: string

  const { showError: showErrorAlert, showSuccess: showSuccessAlert } =
    useAlert()
  const [Room, setRoom] = useState<Colyseus.Room>()
  const [solution, setSolution] = useState([])
  const [currentGuess, setCurrentGuess] = useState('')
  const [isGameWon, setIsGameWon] = useState(false)
  const [isFinish, setIsFinish] = useState(false)
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false)
  const [isStatsModalOpen, setIsStatsModalOpen] = useState(false)
  const [isSettingsModalOpen, setIsSettingsModalOpen] = useState(false)
  const [currentRowClass, setCurrentRowClass] = useState('')
  const [isGameLost, setIsGameLost] = useState(false)
  const [loading, setLoading] = useState(true)
  // const [isDarkMode, setIsDarkMode] = useState(
  //   localStorage.getItem('theme')
  //     ? localStorage.getItem('theme') === 'dark'
  //     : prefersDarkMode
  //     ? true
  //     : false
  // )
  const [isDarkMode, setIsDarkMode] = useState(false)
  const [isHighContrastMode, setIsHighContrastMode] = useState(
    getStoredIsHighContrastMode()
  )
  const [maxGuess, setMaxGuess] = useState(99)
  const [maxWord, setMaxWord] = useState(99)
  const [isRevealing, setIsRevealing] = useState(false)
  const [guesses, setGuesses] = useState<string[]>([])
  const [eventStart, setEventStart] = useState<Number>(0)
  // const [guesses, setGuesses] = useState<string[]>(() => {
  //   const loaded = loadGameStateFromLocalStorage()
  //   if (loaded?.solution !== solution) {
  //     return []
  //   }
  //   const gameWasWon = loaded.guesses.includes(solution)
  //   if (gameWasWon) {
  //     setIsGameWon(true)
  //   }
  //   if (loaded.guesses.length === MAX_CHALLENGES && !gameWasWon) {
  //     setIsGameLost(true)
  //     showErrorAlert(CORRECT_WORD_MESSAGE(solution), {
  //       persist: true,
  //     })
  //   }
  //   return []
  // })

  const [stats, setStats] = useState(() => loadStats())

  const [isHardMode, setIsHardMode] = useState(
    localStorage.getItem('gameMode')
      ? localStorage.getItem('gameMode') === 'hard'
      : false
  )
  const [strDate, setStrDate] = useState<string>('')
  let [timeMs, setTimeMs] = useState<number>(0)

  var timeUp: NodeJS.Timeout

  useEffect(() => {
    // if no game state on load,
    // console.log(solution)
    // show the user the how-to info modal
    // if (!loadGameStateFromLocalStorage()) {
    //   setTimeout(() => {
    //     setIsInfoModalOpen(true)
    //   }, WELCOME_INFO_MODAL_MS)
    // }
    ;(async () => {
      if (!Client) {
        const gameToken = window.location.href.match(/play=([a-zA-Z0-9\-_]+)/)

        if (!gameToken || !gameToken[1]) {
          throw new Error('Game token not found')
        }

        const response = await fetch(
          `${process.env.REACT_APP_GAME_SERVER_URL}/match/exchange-token`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              game_token: gameToken[1],
              game_type: 'wordle',
            }),
          }
        )

        const gameData = await response.json()

        const { token, match_id, player, game_property } = gameData.data

        Client = new Colyseus.Client(process.env.REACT_APP_COLYSEUS_URL)

        const room: Colyseus.Room<any> = await Client.joinById(match_id, {
          // @ris: implement auth di sini
          accessToken: token,
        })

        if (!room) {
          const delayMs = REVEAL_TIME_MS * 5
          showErrorAlert('Event Sudah Berakhir', {
            delayMs,
            // when close pop up back button
            onClose: () => {
              if (window.flutter_inappwebview) {
                window.flutter_inappwebview.callHandler(
                  'onMatchEnded',
                  'wordle'
                )
              }
            },
          })
        }

        // console.log(room)
        userId = player.user_id
        sessionId = room.sessionId

        console.log(`Connected with room ${match_id}`)

        setLoading(false)

        let $maxGuess: number
        let $maxWord: number

        room.onStateChange.once((state) => {
          // console.log(`Using keyword: ${state.keyword}`)
          // set initial guesses (if user reconnected)
          setGuesses(Array.from(state.players.get(sessionId).guesses.values()))
          setSolution(
            Array.from(state.players.get(sessionId).correctWords.values())
          )
          setIsFinish(state.players.get(sessionId).finish)
          setMaxGuess(state.maxGuess)
          setMaxWord(state.maxWordLength)
          setEventStart(state.eventStartAt)

          // Validation if date sekarang < dari date event dimulai
          console.log(state.eventStartAt, Date.now())
          if (Date.now() < state.eventStartAt) {
            const delayMs = REVEAL_TIME_MS * state.maxWordLength
            showErrorAlert('Event Belum Dimulai', {
              delayMs,
              // when close pop up back button
              onClose: () => {
                if (window.flutter_inappwebview) {
                  window.flutter_inappwebview.callHandler(
                    'onMatchEnded',
                    'wordle'
                  )
                }
              },
            })
            return
          }
          // Validation if date sekarang > dari date event selesai
          if (Date.now() > state.eventEndAt) {
            const delayMs = REVEAL_TIME_MS * state.maxWordLength
            showErrorAlert('Event Telah Berakhir', {
              delayMs,
              // when close pop up back button
              onClose: () => {
                if (window.flutter_inappwebview) {
                  window.flutter_inappwebview.callHandler(
                    'onMatchEnded',
                    'wordle'
                  )
                }
              },
            })
            return
          }

          // timer up
          const timerNow = Date.now() - Number(eventStart)
          setTimeMs(timerNow)

          $maxGuess = state.maxGuess
          $maxWord = state.maxWordLength

          // if (eventStart > 0) {
          // console.log(state.players.get(sessionId).finish)
          if (!state.players.get(sessionId).finish) {
            timeUp = setInterval(() => {
              timeMs = Date.now() - Number(state.eventStartAt)
              setTimeMs(timeMs)
              console.log(new Date(1000 * Math.round(timeMs / 1000)))
              var d = new Date(1000 * Math.round(timeMs / 1000)) // round to nearest second
              var str = pad(d.getUTCMinutes()) + ':' + pad(d.getUTCSeconds())
              setStrDate(str)
            }, 1000)
          } else {
            timeMs = state.players.get(sessionId).finishAt - state.eventStartAt
            setTimeMs(timeMs)
            console.log(new Date(1000 * Math.round(timeMs / 1000)))
            var d = new Date(1000 * Math.round(timeMs / 1000)) // round to nearest second
            var str = pad(d.getUTCMinutes()) + ':' + pad(d.getUTCSeconds())
            setStrDate(str)
          }
          // }
        })

        setRoom(room)

        room.onMessage('game_ended', () => {
          showErrorAlert('Game has ended!', { persist: true })
        })

        room.onMessage('guess_error', (data) => {
          setCurrentRowClass('jiggle')
          return showErrorAlert(data.message, {
            onClose: clearCurrentRowClass,
          })
        })

        room.onMessage('guess_accepted', (data) => {
          setGuesses(data.guesses)
          setSolution(data.correctWords)
          setCurrentGuess('')

          setIsRevealing(true)
          // turn this back off after all
          // chars have been revealed
          setTimeout(() => {
            setIsRevealing(false)

            const isWinning = Object.values<string>(data.winners).find(
              (d: string) => d === userId
            )

            if (isWinning) {
              setIsGameWon(true)
              setIsStatsModalOpen(true)
            }

            if (data.guesses.length >= $maxGuess && !isWinning) {
              room.send('guess_lastAttempt', 'sudah berakhir')
              setIsGameLost(true)
              setIsStatsModalOpen(true)
            }
          }, REVEAL_TIME_MS * $maxWord)
        })
      }
    })()
  }, [])

  useEffect(() => {
    if (isDarkMode) {
      document.documentElement.classList.add('dark')
    } else {
      document.documentElement.classList.remove('dark')
    }

    if (isHighContrastMode) {
      document.documentElement.classList.add('high-contrast')
    } else {
      document.documentElement.classList.remove('high-contrast')
    }
  }, [isDarkMode, isHighContrastMode])

  const clearCurrentRowClass = () => {
    setCurrentRowClass('')
  }

  // useEffect(() => {
  //   saveGameStateToLocalStorage({ guesses, solution })
  // }, [guesses])

  useEffect(() => {
    // if (isGameLost) {
    //   const delayMs = REVEAL_TIME_MS * maxWord
    //   showErrorAlert('Coba lagi ya', {
    //     delayMs,
    //     // when close pop up back button
    //     // onClose: () => setIsStatsModalOpen(true),
    //   })
    //   // setTimeout(() => {
    //   //   setIsStatsModalOpen(true)
    //   // }, GAME_LOST_INFO_DELAY)
    // }
  }, [isGameLost, showSuccessAlert])

  const onChar = (value: string) => {
    if (
      unicodeLength(`${currentGuess}${value}`) <= maxWord &&
      guesses.length < maxGuess &&
      !isGameWon
    ) {
      setCurrentGuess(`${currentGuess}${value}`)
    }
  }

  const onDelete = () => {
    setCurrentGuess(
      new GraphemeSplitter().splitGraphemes(currentGuess).slice(0, -1).join('')
    )
  }

  const onEnter = () => {
    if (isGameWon || isGameLost) {
      return
    }

    if (!(unicodeLength(currentGuess) === maxWord)) {
      setCurrentRowClass('jiggle')
      return showErrorAlert(NOT_ENOUGH_LETTERS_MESSAGE, {
        onClose: clearCurrentRowClass,
      })
    }

    if (Room) {
      if (isFinish) {
        setCurrentRowClass('jiggle')
        clearInterval(timeUp)
        return showErrorAlert('Kamu sudah selsai bermain', {
          onClose: clearCurrentRowClass,
        })
      } else {
        Room.send('guess', { word: currentGuess })
      }
    }
  }

  const pad = function (i: number) {
    return ('0' + i).slice(-2)
  }

  // useEffect(() => {
  //   if (!Room) {
  //     console.log(`Have not joined Room`)
  //     return
  //   }

  //   if (eventStart > 0) {
  //     setInterval(() => {
  //       timeMs = Date.now() - Number(eventStart)
  //       setTimeMs(timeMs)
  //       var d = new Date(1000 * Math.round(timeMs / 1000)) // round to nearest second
  //       var str = pad(d.getUTCMinutes()) + ':' + pad(d.getUTCSeconds())
  //       setStrDate(str)
  //     }, 1000)
  //   }
  // }, [Room])

  return (
    <div className="pt-2 pb-8 max-w-7xl mx-auto sm:px-6 lg:px-8 bg-gradient-to-b from-hero-500 to-hero-200 h-screen">
      <div className="flex w-full mx-auto items-center mb-8 mt-14 item-center justify-center">
        <img
          src={titleImg}
          className="relative"
          width={`30%`}
          height={`auto`}
          alt="tebak-yo"
        />
        <div className="absolute right-[12%] box-border h-10 w-16 border-2 bg-white rounded-lg flex-row item-center justify-center">
          <p className="text-[8px] font-semibold text-text_time pt-1 px-1">
            Waktu kamu
          </p>
          <p className="text-[14px] font-bold text-center pt-0"> {strDate} </p>
        </div>
      </div>

      {loading ? (
        <div className="flex flex-grow mx-auto items-center justify-center mb-8 mt-20">
          <ReactLoading type="spin" color="#00000" height={100} width={100} />
        </div>
      ) : (
        <>
          <Grid
            guesses={guesses}
            solution={solution}
            currentGuess={currentGuess}
            isRevealing={isRevealing}
            currentRowClassName={currentRowClass}
            maxGuess={maxGuess}
            maxWord={maxWord}
          />
          <Keyboard
            onChar={onChar}
            onDelete={onDelete}
            onEnter={onEnter}
            guesses={guesses}
            isRevealing={isRevealing}
            solution={solution}
            maxWord={maxWord}
          />
          <StatsModal
            isOpen={isStatsModalOpen}
            handleClose={() => setIsStatsModalOpen(false)}
            guesses={guesses}
            gameStats={stats}
            isGameLost={isGameLost}
            isGameWon={isGameWon}
            handleShare={() => showSuccessAlert(GAME_COPIED_MESSAGE)}
            isHardMode={isHardMode}
            isDarkMode={isDarkMode}
            isHighContrastMode={isHighContrastMode}
          />
        </>
      )}

      <AlertContainer />
    </div>
  )
}

export default App
